<template>
  <div>
    <h4 class="page-header"> Beedee Agent Breakdown</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-3">
            <label>Start Date</label>
            <date-picker
                v-model="start"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group col-md-3">
            <label>End Date</label>
            <date-picker
                v-model="end"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
        </div>
        <vue-good-table :columns="columns" :lineNumbers="true"
                        :pagination-options="{ enabled: true, position: 'bottom' }" :rows="bids"
                        :search-options="{ enabled: true }">
          <div slot="table-actions">
            <download-excel v-if="bids.length"
                            :data="bids"
                            :export-fields="{
                  'Ref':'reference',
                  'Company': 'company',
                  'Counterparty': 'counter_party',
                  'Commission(KES)': 'amount',
                  'Comment':'payment_status',
                  'Deal Date' : 'deal_date',
                  'Transaction Date' : 'transaction_date',
                  'Transaction No' : 'transaction_number',
                  'Agent Firstname' : 'ag_firstname',
                  'Agent Lastname' : 'ag_lastname',
}"
                            :title="'Beedee Agent Report'" class="btn btn-primary" name="Agent Bids.xls">
              <i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: 'Ref',
          field: 'secret'
        },
        {
          label: 'Company',
          field: 'company'
        },
        {
          label: 'Counterparty',
          field: 'counter_party'
        },
        {
          label: 'Commission (KES)',
          field: 'amount'
        },
        {
          label: 'Comment',
          field: 'payment_status'
        },
        {
          label: 'Deal Date',
          field: 'deal_date'
        },
        {
          label: 'Trx Date',
          field: 'transaction_date'
        },
        {
          label: 'Trx No',
          field: 'transaction_number'
        },
        {
          label: 'Beedee Agent',
          field: this.beedeeagent
        },
      ],
      start: moment().add('-1', 'day').toDate(),
      end: moment().toDate()
    }
  },
  computed: {
    ...mapGetters({
      bids: 'getBeedeeAgentBids'
    }),
    period() {
      return [this.start, this.end].join();
    }
  },
  watch: {
    period() {
      if (this.start && this.end) {
        this.fetch();
      }
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    beedeeagent(rowObj) {
      return [rowObj.ag_firstname, rowObj.ag_lastname].join(' ');
    },
    fetch() {
      this.$store.dispatch('fetchBeedeeAgentBids', {start: this.start, end: this.end});
    },
    getBidBonds() {
      if (!this.bids && !this.bids.results.length) {
        this.fetch();
      }
    },
  }
}
</script>