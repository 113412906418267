var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"page-header"},[_vm._v(" Beedee Agent Breakdown")]),_c('div',{staticClass:"panel panel-inverse"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Start Date")]),_c('date-picker',{attrs:{"format":"yyyy-MM-dd","input-class":"form-control bg-white"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("End Date")]),_c('date-picker',{attrs:{"format":"yyyy-MM-dd","input-class":"form-control bg-white"},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"lineNumbers":true,"pagination-options":{ enabled: true, position: 'bottom' },"rows":_vm.bids,"search-options":{ enabled: true }}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[(_vm.bids.length)?_c('download-excel',{staticClass:"btn btn-primary",attrs:{"data":_vm.bids,"export-fields":{
                  'Ref':'reference',
                  'Company': 'company',
                  'Counterparty': 'counter_party',
                  'Commission(KES)': 'amount',
                  'Comment':'payment_status',
                  'Deal Date' : 'deal_date',
                  'Transaction Date' : 'transaction_date',
                  'Transaction No' : 'transaction_number',
                  'Agent Firstname' : 'ag_firstname',
                  'Agent Lastname' : 'ag_lastname',
},"title":'Beedee Agent Report',"name":"Agent Bids.xls"}},[_c('i',{staticClass:"fa fa-file-excel"}),_vm._v(" Export Excel ")]):_vm._e()],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }